import * as React from "react";
import { StaticImage } from "gatsby-plugin-image";
import { Container, Row, Col } from "react-bootstrap";

import Meta from "../components/meta";
import Layout from "../components/layout";

// Slider setting
import Slider from "react-slick";
import VideoSrc from "../movies/works.mp4";
const sliderSettings = {
  dots: false,
  arrows: false,
  infinite: false,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  pauseOnFocus: false,
  pauseOnHover: false,
  autoplay: true,
  autoplaySpeed: 8000,
};

// markup
const WorksPage = ({ location }) => {
  return (
    <Layout>
      {/* サイトタイトル */}
      <Meta title="施工実例" />

      <div
        className="slide"
        style={{
          fontSize: 0,
          padding: "0 !important",
        }}
      >
        <Slider {...sliderSettings}>
          <div className="videowrap">
            <video muted autoPlay playsInline>
              <track kind="captions" srcLang="ja" />
              <source src={VideoSrc} type="video/mp4" />
            </video>
          </div>
        </Slider>
      </div>

      <div style={{ zIndex: 10, position: "relative" }}>
        <div className="container">
          <nav className="breadcrumb" aria-label="Breadcrumb">
            <ol className="breadcrumb__list">
              <li className="breadcrumb__item">
                <a className="breadcrumb__link" href="/">
                  ホーム
                </a>
                <span className="breadcrumb__separator" aria-hidden="true">
                  {" "}
                  &gt;{" "}
                </span>
              </li>
              <li className="breadcrumb__item">
                <a aria-current="page" className="breadcrumb__link" href="/works/">
                  施工実例
                </a>
              </li>
            </ol>
          </nav>
        </div>
      </div>

      <section className="sec position-relative bg-illust">
        {/* <div className="illust illust--1">
          <StaticImage alt="illust" src="../images/illust_01.png" />
        </div>
        <div className="illust illust--2">
          <StaticImage alt="illust" src="../images/illust_02.png" />
        </div> */}
        <Container>
          <p className="text-right">
            施工実績<span style={{ fontSize: "120%", fontWeight: "bold" }}>856</span>件 <span style={{ fontSize: "120%", fontWeight: "bold" }}>83.88</span>MW
          </p>
          <Row>
            <Col md={4} className="mb-4">
              <StaticImage alt="施工実例" src="../images/works/set-18@2x.png" />
            </Col>
            <Col md={4} className="mb-4">
              <StaticImage alt="施工実例" src="../images/works/set-17@2x.png" />
            </Col>
            <Col md={4} className="mb-4">
              <StaticImage alt="施工実例" src="../images/works/set-16@2x.png" />
            </Col>
          </Row>
          <Row>
            <Col md={4} className="mb-4">
              <StaticImage alt="施工実例" src="../images/works/set-15@2x.png" />
            </Col>
            <Col md={4} className="mb-4">
              <StaticImage alt="施工実例" src="../images/works/set-14@2x.png" />
            </Col>
            <Col md={4} className="mb-4">
              <StaticImage alt="施工実例" src="../images/works/set-13@2x.png" />
            </Col>
          </Row>
          <Row>
            <Col md={4} className="mb-4">
              <StaticImage alt="施工実例" src="../images/works/set-12@2x.png" />
            </Col>
            <Col md={4} className="mb-4">
              <StaticImage alt="施工実例" src="../images/works/set-11@2x.png" />
            </Col>
            <Col md={4} className="mb-4">
              <StaticImage alt="施工実例" src="../images/works/set-10@2x.png" />
            </Col>
          </Row>
          <Row>
            <Col md={4} className="mb-4">
              <StaticImage alt="施工実例" src="../images/works/set-9@2x.png" />
            </Col>
            <Col md={4} className="mb-4">
              <StaticImage alt="施工実例" src="../images/works/set-8@2x.png" />
            </Col>
            <Col md={4} className="mb-4">
              <StaticImage alt="施工実例" src="../images/works/set-7@2x.png" />
            </Col>
          </Row>
          <Row>
            <Col md={4} className="mb-4">
              <StaticImage alt="施工実例" src="../images/works/set-6@2x.png" />
            </Col>
            <Col md={4} className="mb-4">
              <StaticImage alt="施工実例" src="../images/works/set-5@2x.png" />
            </Col>
            <Col md={4} className="mb-4">
              <StaticImage alt="施工実例" src="../images/works/set-4@2x.png" />
            </Col>
          </Row>
          <Row>
            <Col md={4} className="mb-4">
              <StaticImage alt="施工実例" src="../images/works/set-3@2x.png" />
            </Col>
            <Col md={4} className="mb-4">
              <StaticImage alt="施工実例" src="../images/works/set-2@2x.png" />
            </Col>
            <Col md={4} className="mb-4">
              <StaticImage alt="施工実例" src="../images/works/set@2x.png" />
            </Col>
          </Row>
        </Container>
      </section>
    </Layout>
  );
};

export default WorksPage;
